import PropTypes, { bool } from "prop-types";
import React from "react";
import { navigate } from "gatsby";
import logoLightPng from "images/earthSpeakr_logo_white.png";
import logoDarkPng from "images/earthSpeakr_logo_black.png";
import "./Logo.scss";
import { motion } from "framer-motion";

const Logo = React.memo(
    ({ locale, logoText, logoLight, className, onClick }) => {
        const logoTransition = {
            exit: {
                opacity: 0,
                transition: {
                    type: "tween",
                    ease: "easeOut",
                    duration: 1,
                },
            },

            enter: {
                opacity: 1,
                transition: {
                    type: "tween",
                    ease: "easeIn",
                    duration: 1,
                },
            },
        };

        return (
            <motion.div
                variants={logoTransition}
                initial="exit"
                animate="enter"
                className={`logo ${className}`}
            >
                <a
                    href={`/${locale}`}
                    onClick={(e) => {
                        e.preventDefault();
                        if (!onClick) {
                            navigate(`/${locale}`);
                        } else {
                            onClick();
                        }
                    }}
                >
                    {logoLight ? (
                        <img
                            src={logoLightPng}
                            alt={logoText || "Earth Speakr"}
                        />
                    ) : (
                        <img
                            src={logoDarkPng}
                            alt={logoText || "Earth Speakr"}
                        />
                    )}
                </a>
            </motion.div>
        );
    }
);

Logo.defaultProps = {
    logoLight: true,
};

Logo.propTypes = {
    logoText: PropTypes.string,
    locale: PropTypes.string.isRequired,
    logoLight: bool,
};

export default Logo;
