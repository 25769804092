import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import GithubSlugger from "github-slugger";

const RichText = React.memo(({ content, ...rest }) => {
    const containerRef = useRef();

    useEffect(() => {
        if (containerRef.current) {
            [
                ...containerRef.current.querySelectorAll("h1,h2,h3,h4,h5,h6"),
            ].forEach((el) => {
                el.id = GithubSlugger.slug(el.textContent, false);
            });
        }
    }, [content]);

    return (
        <motion.div
            ref={containerRef}
            dangerouslySetInnerHTML={{
                __html: content.replace(/&nbsp;/g, " "),
            }}
            {...rest}
        />
    );
});

export default RichText;
