import React from "react";
import { motion } from "framer-motion";
import { ScrollingProvider } from "react-scroll-section";
import ArticleContent from "components/article-content";
import "./ArticleTemplate.scss";

import loadable from "@loadable/component";
const ArticleSectionNavigation = loadable(() =>
    import("components/article-section-navigation")
);

const ArticleTemplate = React.memo(
    ({
        locale,
        sections,
        body,
        title,
        id,
        cookiePolicyLabel,
        consentLabels,
        carousel,
        resetPasswordLabels,
    }) => {
        // Warning - these originalId (legal page, parent consent and reset password) can neither be changed in datocms or here
        const renewCookiePolicy = id === "4691420";
        const isParentalConsent = id === "4856589";
        const isResetPassword = id === "5115955";
        const isPressAndMedia = id === "4563136";

        const animateArticleTemplate = {
            enter: {
                transition: {
                    staggerChildren: 0.2,
                },
            },
        };

        const articleContentProps = {
            title,
            articleText: body,
            sections: sections.length > 0 ? sections : null,
            carousel: carousel.length > 0 ? carousel : null,
            isParentalConsent,
            isResetPassword,
            resetPasswordLabels,
            consentLabels,
            isPressAndMedia,
            locale,
        };

        const ArticleTemplateContent = (
            <motion.div
                key="article-template"
                initial="initial"
                animate="enter"
                exit="exit"
                className="article-template"
                variants={animateArticleTemplate}
            >
                <ArticleContent {...articleContentProps} />

                {articleContentProps.sections && (
                    <ArticleSectionNavigation
                        language={locale}
                        items={sections}
                        renewCookiePolicy={renewCookiePolicy}
                        cookiePolicyLabel={cookiePolicyLabel}
                    />
                )}
            </motion.div>
        );

        return articleContentProps.sections ? (
            <ScrollingProvider>{ArticleTemplateContent}</ScrollingProvider>
        ) : (
            ArticleTemplateContent
        );
    }
);

export default ArticleTemplate;
