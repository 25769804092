import React from "react";
import { Helmet } from "react-helmet";

const CookieConsent = React.memo((language) => {
    let dataCulture = language.language;
    if (dataCulture === "mt" || dataCulture === "ga") {
        dataCulture = "en";
    }

    return (
        <>
            <Helmet>
                <script
                    id="CookieConsent"
                    src="https://policy.app.cookieinformation.com/uc.js"
                    data-culture={dataCulture}
                    type="text/javascript"
                    defer
                    async
                ></script>
            </Helmet>
        </>
    );
});

export default CookieConsent;
