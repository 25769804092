import React from "react";
import { motion } from "framer-motion";
import "./ArticleContent.scss";
import easings from "constants/easings";
import useWindowSize, { breakpoints } from "hooks/useWindowSize";
import loadable from "@loadable/component";
import Heading from "../heading/Heading";
import RichText from "../rich-text/RichText";

const ParentalConsent = loadable(() => import("components/parental-consent"));
const ResetPassword = loadable(() => import("components/reset-password"));
const ArticleMediaSection = loadable(() =>
    import("components/article-media-section")
);
const MobileCarousel = loadable(() => import("components/mobile-carousel"));
const Carousel = loadable(() => import("components/carousel"));

const ArticleContent = React.memo(
    ({
        title,
        articleText,
        locale,
        sections,
        carousel,
        isParentalConsent,
        isResetPassword,
        resetPasswordLabels,
        consentLabels,
        isPressAndMedia,
    }) => {
        const size = useWindowSize();
        const desktopCarousel = size.width > breakpoints.md;

        const animateArticleItem = {
            initial: {
                y: 20,
                opacity: 0,
            },
            enter: {
                y: 0,
                opacity: 1,
                transition: {
                    type: "spring",
                    damping: 50,
                    ease: easings.one,
                    duration: 0.5,
                },
            },
        };

        const animateArticleContainer = {
            initial: {
                opacity: 1,
            },

            enter: {
                opacity: 1,
                transition: {
                    when: "beforeChildren",
                    staggerChildren: 0.1,
                },
            },
        };

        return (
            <motion.div
                initial="initial"
                exit="initial"
                animate="enter"
                variants={animateArticleContainer}
                className="articleContainer"
            >
                <article>
                    {articleText !== "" && articleText !== null ? (
                        <>
                            <div className="top-content">
                                <div className="article_content">
                                    <Heading
                                        as="h1"
                                        className="article_content__title"
                                        variants={animateArticleItem}
                                        slug={title}
                                    >
                                        {title}
                                    </Heading>
                                    {articleText !== "" &&
                                        articleText !== null && (
                                            <RichText
                                                className="article_content__col article_content__col--left"
                                                variants={animateArticleItem}
                                                content={articleText}
                                            />
                                        )}
                                </div>
                            </div>
                            {carousel && !desktopCarousel && (
                                <MobileCarousel slides={carousel} />
                            )}
                            {carousel && desktopCarousel && (
                                <Carousel slides={carousel} />
                            )}
                        </>
                    ) : (
                        <>
                            <Heading
                                as="h1"
                                className="article_content__title"
                                variants={animateArticleItem}
                                slug={title}
                            >
                                {title}
                            </Heading>
                            {carousel && !desktopCarousel && (
                                <>
                                    <MobileCarousel slides={carousel} />
                                </>
                            )}
                            {carousel && desktopCarousel && (
                                <>
                                    <Carousel slides={carousel} />
                                </>
                            )}
                        </>
                    )}
                    {sections && (
                        <ArticleMediaSection
                            articleSections={sections}
                            animateArticleItem={animateArticleItem}
                            isPressAndMedia={isPressAndMedia}
                            locale={locale}
                        />
                    )}
                </article>
                {isParentalConsent && (
                    <ParentalConsent labels={consentLabels} />
                )}
                {isResetPassword && (
                    <ResetPassword labels={resetPasswordLabels} />
                )}
            </motion.div>
        );
    }
);

export default ArticleContent;
