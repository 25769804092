import React, { memo, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { navigate } from "gatsby";
import NavigationPillar from "components/navigation-pillar";
import ArticleTemplate from "templates/article/ArticleTemplate";
import Logo from "components/logo";
import "./Layout.scss";
import BackgroundGradient from "components/background-gradient";
import CookieConsent from "../cookie-consent/CookieConsent";
import { motion } from "framer-motion";
import useWindowSize, { breakpoints } from "hooks/useWindowSize";
import easings from "constants/easings";
import LanguageSelector from "components/language-selector";

const ArticleLayout = ({ children, data, location, pageContext, pageType }) => {
    const { locale } = pageContext;
    const {
        serviceMenuLinks,
        nativeLanguages,
        currentSlug,
        globalTexts,
        cookiePolicyLabel,
        frontpageData,
    } = data;

    const [transitionOut, setTransitionOut] = useState(false);
    const size = useWindowSize();
    const smallScreen = size.width < breakpoints.md;

    // LanguageSelector state
    const [isLanguageSelectorActive, setIsLanguageSelectorActive] =
        useState(false);

    const backgroundTransition = {
        initial: {
            opacity: 1,
            [size.width > breakpoints.md ? "x" : "y"]: "0%",
        },

        exit: {
            opacity: 1,
            [size.width > breakpoints.md ? "x" : "y"]:
                size.width > breakpoints.md
                    ? `${size.width - 300}px`
                    : `${size.height - 160}px`,
            transition: {
                delay: 0.2,
                type: "tween",
                ease: easings.heavy,
                duration: 0.6,
            },
        },
    };

    return (
        <>
            <CookieConsent language={locale} />

            {children}

            <Logo
                locale={locale}
                className="article"
                logoLight={false}
                onClick={() => setTransitionOut(true)}
            />

            <BackgroundGradient
                className="article"
                transitionOut={transitionOut}
            ></BackgroundGradient>

            <motion.div
                variants={backgroundTransition}
                animate={transitionOut ? "exit" : "initial"}
                className="background-transition"
                onAnimationComplete={() => {
                    if (transitionOut) {
                        navigate(`/${locale}`);
                    }
                }}
            />

            <main
                className={`articlepage ${
                    transitionOut ? "articlepage--fadeOut" : ""
                }`}
            >
                <AnimatePresence exitBeforeEnter>
                    <ArticleTemplate
                        key={location && location.key ? location.key : 0}
                        locale={locale}
                        consentLabels={data.consentLabels}
                        resetPasswordLabels={data.resetPasswordLabels}
                        cookiePolicyLabel={cookiePolicyLabel}
                        {...data.currentArticlePage}
                    />
                </AnimatePresence>
                {!smallScreen && (
                    <LanguageSelector
                        nativeLanguages={nativeLanguages}
                        globalTexts={globalTexts}
                        isLanguageSelectorActive={isLanguageSelectorActive}
                        setIsLanguageSelectorActive={
                            setIsLanguageSelectorActive
                        }
                        currentSlug={currentSlug}
                        pageType={pageType}
                    />
                )}
            </main>

            <NavigationPillar
                text={frontpageData.aboutPageDescription}
                label={frontpageData.articlePageLink?.menuTitle}
                link={`/${locale}/${frontpageData.articlePageLink?.slug}`}
                takeActionLink={`/${locale}/${frontpageData.takeActionLink?.slug}`}
                takeActionLabel={frontpageData.takeActionLink?.menuTitle}
                logoText={frontpageData.logoText}
                locale={locale}
                items={data.articlePages.edges}
                serviceMenuLinks={serviceMenuLinks.edges}
                location={location}
                nativeLanguages={nativeLanguages}
                currentSlug={currentSlug}
                globalTexts={globalTexts}
                pageType={pageType}
                transitionOut={transitionOut}
                setTransitionOut={setTransitionOut}
            />
        </>
    );
};

export default memo(ArticleLayout);
