import React from "react";
// import { Gradient } from "react-gradient";
import { motion } from "framer-motion";
import "./BackgroundGradient.scss";
// import variables from "scss/base/variables.scss";

const animateBackground = {
    initial: {
        opacity: 1,
        transition: {
            type: "tween",
            ease: "easeIn",
            duration: 1,
        },
    },

    exit: {
        opacity: 0,
        transition: {
            type: "tween",
            ease: "easeOut",
            duration: 2,
        },
    },
};

const BackgroundGradient = React.memo(
    ({ children, className, transitionOut }) => {
        return (
            <motion.div
                variants={animateBackground}
                animate={transitionOut ? "exit" : "initial"}
                className={`background-gradient ${className ? className : ""}`}
            >
                {children}
            </motion.div>
        );
    }
);

export default BackgroundGradient;
