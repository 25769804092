import PropTypes from "prop-types";
import React from "react";
import OlafurEliassonLogo from "images/OlafurEliasson_LogoBlack.inline.svg";
import { navigate } from "gatsby";
import { motion } from "framer-motion";

import "./LogoText.scss";

const LogoText = React.memo(({ locale, onClick }) => {
    const animateLogoText = {
        initial: {
            opacity: 0,
            transition: {
                type: "tween",
                ease: "easeOut",
                duration: 1,
            },
        },

        enter: {
            opacity: 1,
            transition: {
                type: "tween",
                ease: "easeIn",
                duration: 1,
            },
        },
    };

    return (
        <motion.div
            className="logo-text"
            initial="initial"
            exit="exit"
            animate="enter"
            variants={animateLogoText}
        >
            <a
                href={`/${locale}`}
                onClick={(e) => {
                    e.preventDefault();
                    if (!onClick) {
                        navigate(`/${locale}`);
                    } else {
                        onClick();
                    }
                }}
            >
                <OlafurEliassonLogo />
            </a>
        </motion.div>
    );
});

LogoText.propTypes = {
    logoText: PropTypes.string,
};

export default LogoText;
