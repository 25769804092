import React from "react";
import classNames from "classnames";
import "./TogglePillarButton.scss";

const TogglePillarButton = React.memo(
    ({ onClick, style, className, isPillarButtonActive }) => {
        const togglePillarClass = classNames("toggle-pillar-button__lines", {
            "toggle-pillar-button__lines--active": isPillarButtonActive,
        });
        return (
            <div
                onClick={(e) => {
                    e.preventDefault();
                    onClick();
                }}
                className={`toggle-pillar-button ${className}`}
                style={style}
                aria-label="toggle pillar button"
            >
                <div className={togglePillarClass}>
                    <span></span>
                </div>
            </div>
        );
    }
);

export default TogglePillarButton;
