import React from "react";
import { motion } from "framer-motion";

const Heading = React.memo(({ as, slug, children, ...rest }) => {
    const finalProps = {
        ...rest,
        id: children?.toLowerCase().replace(/\s/g, "-"),
    };

    switch (as) {
        case "h1":
            return <motion.h1 {...finalProps}>{children}</motion.h1>;
        case "h2":
        default:
            return <motion.h2 {...finalProps}>{children}</motion.h2>;
        case "h3":
            return <motion.h3 {...finalProps}>{children}</motion.h3>;
        case "h4":
            return <motion.h4 {...finalProps}>{children}</motion.h4>;
        case "h5":
            return <motion.h5 {...finalProps}>{children}</motion.h5>;
        case "h6":
            return <motion.h6 {...finalProps}>{children}</motion.h6>;
    }
});

Heading.defaultProps = {
    as: "h2",
};

export default Heading;
