import React from "react";
import ArticleLayout from "components/layout/ArticleLayout";

const Layout = React.memo(({ children, pageContext, data, location }) => {
    const pageType = pageContext.pageType;
    if (pageType === "article") {
        return (
            <ArticleLayout
                location={location}
                data={data}
                pageContext={pageContext}
                pageType={pageType}
            >
                {children}
            </ArticleLayout>
        );
    }
});

export default Layout;
