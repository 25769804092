import React, { useState } from "react";
import ArrowIcon from "images/arrow-right.inline.svg";
import { useCycle, motion } from "framer-motion";
import LogoText from "components/logo-text";
import "./NavigationPillar.scss";
import { Link } from "gatsby";
import { navigate } from "gatsby";
import useWindowSize, { breakpoints } from "hooks/useWindowSize";
import TogglePillarButton from "components/toggle-pillar-button";
import classNames from "classnames";
import Navigation from "components/navigation";

const NavigationPillar = React.memo(
    ({
        label,
        link,
        logoText,
        takeActionLink,
        takeActionLabel,
        setFadeOutContent,
        locale,
        items,
        serviceMenuLinks,
        location,
        nativeLanguages,
        currentSlug,
        globalTexts,
        pageType,
        transitionOut,
        setTransitionOut,
    }) => {
        const size = useWindowSize();
        const isMobile = size.width < breakpoints.md;
        const isDesktop = size.width > breakpoints.md;
        const [transformPillar, setTransformPillar] = useCycle(false, true);
        const [currentLink, setCurrentLink] = useState(null);
        const [isPillarButtonActive, setPillarButtonActive] = useState(false);

        const animatePillar = (e, link) => {
            if (e) {
                e.preventDefault();
            }

            setCurrentLink(link);

            if (link) {
                setPillarButtonActive(false);

                if (setFadeOutContent !== undefined) {
                    setFadeOutContent(true);
                }

                if (pageType !== "article") {
                    setTransformPillar(true);
                }
                if (pageType === "article") {
                    navigate(link);
                }
            } else {
                return;
            }
        };

        const animatePillarButton = (e) => {
            if (e) {
                e.preventDefault();
            }

            setPillarButtonActive(true);
            if (isPillarButtonActive) {
                setPillarButtonActive(false);
            }
        };

        const animatePillarIn = {
            initial: {
                opacity: 0,
                [!isMobile ? "x" : "y"]: "100%",
            },

            exit: {
                opacity: 1,
                x: !isMobile ? "100%" : 0,
                y: isMobile ? "100%" : 0,
                transition: {
                    type: "spring",
                    ease: "easeOut",
                },
            },

            enter: {
                opacity: 1,
                [!isMobile ? "x" : "y"]: 0,
                transition: {
                    type: "tween",
                    ease: "easeIn",
                    when: "beforeChildren",
                    staggerChildren: 0.2,
                },
            },
        };

        const animatePillarItems = {
            initial: {
                opacity: 0,
            },

            enter: {
                opacity: 1,
                transition: {
                    type: "tween",
                    ease: "easeIn",
                },
            },
        };

        const backgroundTransition = {
            initial: {
                [isDesktop && pageType !== "article" ? "x" : "y"]: "100%",
                opacity: 1,
                transition: {
                    type: "spring",
                    damping: 50,
                    ease: "easeIn",
                    duration: 0.6,
                },
            },

            exit: {
                [isDesktop ? "x" : "y"]: "0%",
                opacity: 1,
                transition: {
                    type: "spring",
                    damping: 50,
                    ease: "easeOut",
                    duration: 0.6,
                },
            },
        };

        const navigationTransitionFrontpage = {
            initial: {
                [isDesktop ? "x" : "y"]: "100%",
                opacity: 0, // pageType === "article" ? 0 : 1,
                transition: {
                    type: "spring",
                    damping: 50,
                    ease: "easeIn",
                    duration: 0.3,
                },
            },

            exit: {
                [isDesktop ? "x" : "y"]: "100%",
                transition: {
                    type: "spring",
                    damping: 50,
                    ease: "easeIn",
                    duration: 0.3,
                },
            },

            enter: {
                [isDesktop ? "x" : "y"]: "0%",
                opacity: 1,
                transition: {
                    type: "spring",
                    damping: 50,
                    ease: "easeOut",
                    duration: 0.3,
                },
            },
        };

        // We only want transform animation on mobile for articlepage
        const navigationTransitionArticlePage = {
            initial: {
                [isMobile ? "y" : ""]: "100%",
                opacity: 0,
                display: "none",
            },

            exit: {
                [isMobile ? "y" : ""]: "100%",
                opacity: 0,
                transitionEnd: {
                    display: "none",
                },
            },

            enter: {
                [isMobile ? "y" : ""]: "0%",
                opacity: 1,
                display: "block",
                transition: {
                    type: "spring",
                    damping: 50,
                    ease: "easeIn",
                    duration: 0.3,
                },
            },
        };

        const transitionClass = classNames("navigation-transition", {
            "navigation-transition--active": isPillarButtonActive,
        });

        return (
            <>
                <motion.div
                    className="navigation-pillar"
                    initial="initial"
                    variants={animatePillarIn}
                    animate={transformPillar ? "exit" : "enter"}
                >
                    <div className="navigation-pillar__fake-background"></div>
                    {isDesktop && (
                        <motion.span variants={animatePillarItems}>
                            <LogoText
                                logoText={logoText}
                                locale={locale}
                                onClick={() => {
                                    if (transitionOut !== undefined) {
                                        setTransitionOut(true);
                                    } else {
                                        return;
                                    }
                                }}
                            />
                        </motion.span>
                    )}
                    <TogglePillarButton
                        className="toggle-pillar"
                        isPillarButtonActive={isPillarButtonActive}
                        onClick={() => animatePillarButton()}
                    />
                    <Link
                        to={link}
                        onClick={(e) => animatePillar(e, link)}
                        className="navigation-pillar__link"
                    >
                        <motion.span
                            key="navigation-pillar__label"
                            className="navigation-pillar__label"
                            variants={animatePillarItems}
                        >
                            {label}
                            <ArrowIcon />
                        </motion.span>
                    </Link>
                    <Link
                        to={takeActionLink}
                        className="navigation-pillar__link navigation-pillar__link--black"
                        onClick={(e) => animatePillar(e, takeActionLink)}
                    >
                        <motion.span
                            key="navigation-pillar__label"
                            className="navigation-pillar__label"
                            variants={animatePillarItems}
                        >
                            {takeActionLabel}
                            <ArrowIcon />
                        </motion.span>
                    </Link>
                </motion.div>

                <motion.div
                    variants={
                        pageType === "frontpage"
                            ? navigationTransitionFrontpage
                            : navigationTransitionArticlePage
                    }
                    initial="initial"
                    animate={
                        isPillarButtonActive && !transitionOut
                            ? "enter"
                            : "exit"
                    }
                    className={transitionClass}
                >
                    <Navigation
                        items={items}
                        locale={locale}
                        serviceMenuLinks={serviceMenuLinks}
                        location={location}
                        nativeLanguages={nativeLanguages}
                        currentSlug={currentSlug}
                        globalTexts={globalTexts}
                        setPillarButtonActive={setPillarButtonActive}
                        isPillarButtonActive={isPillarButtonActive}
                        pageType={pageType}
                    />
                </motion.div>
                {transformPillar && (
                    <motion.div
                        variants={backgroundTransition}
                        initial="initial"
                        exit="exit"
                        animate={transformPillar ? "exit" : "initial"}
                        className="background-transition"
                        onAnimationComplete={() => {
                            navigate(currentLink);
                        }}
                    ></motion.div>
                )}
            </>
        );
    }
);

export default NavigationPillar;
