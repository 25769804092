import React from "react";
import PropTypes from "prop-types";
import useWindowSize, { breakpoints } from "hooks/useWindowSize";
import "./GradientText.scss";

const GradientText = ({
    gradientText,
    heading,
    paragraph,
    fontSize,
    deviceFontSize,
    width,
    deviceWidth,
    textTransform,
}) => {
    const size = useWindowSize();
    const deviceBreakPoint = size.width < breakpoints.md;

    return (
        <>
            {heading ? (
                <div className="gradient-container">
                    <h1
                        className="gradient-text"
                        style={{
                            fontSize: deviceBreakPoint
                                ? deviceFontSize
                                : fontSize,
                            width: deviceBreakPoint ? deviceWidth : width,
                            textTransform: textTransform,
                        }}
                        dangerouslySetInnerHTML={{
                            __html: `${gradientText}`,
                        }}
                    ></h1>
                </div>
            ) : (
                paragraph && (
                    <div className="gradient-container">
                        <p
                            className="gradient-text"
                            style={{
                                fontSize: deviceBreakPoint
                                    ? deviceFontSize
                                    : fontSize,
                                width: deviceBreakPoint ? deviceWidth : width,
                                textTransform: textTransform,
                            }}
                            dangerouslySetInnerHTML={{
                                __html: `${gradientText}`,
                            }}
                        ></p>
                    </div>
                )
            )}
        </>
    );
};

GradientText.propTypes = {
    gradientText: PropTypes.string.isRequired,
    heading: PropTypes.bool,
    paragraph: PropTypes.bool,
    fontSize: PropTypes.string,
    deviceFontSize: PropTypes.string,
    width: PropTypes.string,
    deviceWidth: PropTypes.string,
    textTransform: PropTypes.string,
};

GradientText.defaultProps = {
    heading: false,
    paragraph: true,
    fontSize: "1.6rem",
    deviceFontSize: "1.6rem",
    width: "100%",
    deviceWidth: "100%",
    textTransform: "",
};

export default GradientText;
