import React, { useState } from "react";
import { navigate } from "gatsby";
import { motion } from "framer-motion";
import classNames from "classnames";
import "./Navigation.scss";
import LanguageSelector from "../language-selector/LanguageSelector";

const Navigation = React.memo(
    ({
        items,
        serviceMenuLinks,
        location,
        locale,
        nativeLanguages,
        currentSlug,
        globalTexts,
        setPillarButtonActive,
        isPillarButtonActive,
        pageType,
    }) => {
        // LanguageSelector state
        const [isLanguageSelectorActive, setIsLanguageSelectorActive] =
            useState(false);

        const linkClicked = (e, articleUrl) => {
            if (e) {
                e.preventDefault();
            }
            setPillarButtonActive(false);
            navigate(articleUrl);
        };

        const animateNavigation = {
            initial: {
                opacity: 0,
                transition: {
                    type: "spring",
                    damping: 50,
                    when: "beforeChildren",
                    staggerChildren: 0.05,
                },
            },

            exit: {
                opacity: 0,
                transition: {
                    type: "spring",
                    damping: 50,
                    when: "afterChildren",
                    staggerChildren: 0.05,
                },
            },

            enter: {
                opacity: 1,
                transition: {
                    type: "spring",
                    damping: 50,
                    when: "beforeChildren",
                    staggerChildren: 0.05,
                },
            },
        };

        const animateNavigationItems = {
            initial: {
                y: 20,
                opacity: 0,
            },

            exit: {
                y: 20,
                opacity: 0,
                transition: {
                    typ: "tween",
                    ease: "easeOut",
                },
            },

            enter: {
                y: 0,
                opacity: 1,
                transition: {
                    type: "spring",
                    damping: 50,
                    ease: "easeIn",
                },
            },
        };

        const articleLinks = [];

        for (let index = 0; index < items.length + 1; index++) {
            if (index !== items.length) {
                const articleItem = items[index];

                const articleUrl = `/${locale}/${articleItem.node.slug}/`;
                const articleLinkItemClassName = classNames(
                    `navigation__list__item`,
                    {
                        "navigation__list__item--first": index === 0,
                        "navigation__list__item--active":
                            location.pathname === articleUrl,
                    }
                );

                if (!articleItem.node.hideFromNavigation) {
                    articleLinks.push(
                        <motion.li
                            variants={animateNavigationItems}
                            key={index}
                        >
                            <a
                                href={articleUrl}
                                className={articleLinkItemClassName}
                                onClick={(e) => linkClicked(e, articleUrl)}
                                key={index}
                            >
                                {articleItem.node.menuTitle}
                            </a>
                        </motion.li>
                    );
                }
            } else {
                const mapLink = (
                    <motion.li
                        key={articleLinks.length}
                        variants={animateNavigationItems}
                    >
                        <a
                            className="navigation__list__item"
                            href={`/${locale}/map`}
                        >
                            {globalTexts.mapMenuLabel}
                        </a>
                    </motion.li>
                );
                articleLinks.splice(1, 0, mapLink);
            }
        }

        const researchLinkUrl = `/${locale}/whatkidsaresaying/`;
        const researchLink = (
            <motion.li variants={animateNavigationItems} key="research">
                <a
                    className={classNames(`navigation__list__item`, {
                        "navigation__list__item--active":
                            location.pathname === researchLinkUrl,
                    })}
                    href={researchLinkUrl}
                >
                    What kids are saying
                </a>
            </motion.li>
        );
        articleLinks.splice(4, 0, researchLink);

        const serviceLinks = serviceMenuLinks.map((serviceItem, key) => {
            const serviceLinkUrl = `/${locale}/${serviceItem.node.internalLink.slug}/`;

            const articleServiceLinkItemClassName = classNames(
                `service-nav__list__item`,
                {
                    "service-nav__list__item--active":
                        location.pathname === serviceLinkUrl,
                }
            );

            return (
                <motion.li variants={animateNavigationItems} key={key}>
                    {serviceItem.node.linkType === "link" &&
                        serviceItem.node.internalLink && (
                            <a
                                href={serviceLinkUrl}
                                className={articleServiceLinkItemClassName}
                                onClick={(e) => linkClicked(e, serviceLinkUrl)}
                                key={key}
                            >
                                {serviceItem.node.menuTitle}
                            </a>
                        )}
                </motion.li>
            );
        });

        const streamingLink = (
            <motion.li variants={animateNavigationItems} key="streaming">
                <a
                    className="navigation__list__item"
                    href={`/${locale}/streaming`}
                >
                    {globalTexts.streamingMenuLabel}
                </a>
            </motion.li>
        );
        articleLinks.splice(5, 0, streamingLink);

        const navigationClassName = classNames(`navigation`, {
            "navigation--active": isPillarButtonActive,
        });

        return (
            <motion.nav
                className={navigationClassName}
                variants={animateNavigation}
                initial="initial"
                exit="exit"
                animate={isPillarButtonActive ? "enter" : "exit"}
            >
                <ul className="navigation__list">{articleLinks}</ul>
                <ul className="service-nav__list">
                    {serviceLinks}
                    <motion.li variants={animateNavigationItems}>
                        <LanguageSelector
                            nativeLanguages={nativeLanguages}
                            currentSlug={currentSlug}
                            globalTexts={globalTexts}
                            setIsLanguageSelectorActive={
                                setIsLanguageSelectorActive
                            }
                            isLanguageSelectorActive={isLanguageSelectorActive}
                            pageType={pageType}
                        />
                    </motion.li>
                </ul>
            </motion.nav>
        );
    }
);

export default Navigation;
