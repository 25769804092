import React from "react";
// import { Link } from "gatsby";
import classNames from "classnames";
import { motion } from "framer-motion";
import useWindowSize, { breakpoints } from "hooks/useWindowSize";
import GradientText from "components/gradient-text";
import ArrowRight from "../../images/arrow-right.inline.svg";
import CloseSvg from "../../images/close.inline.svg";
import { window, exists } from "browser-monads";
import "./LanguageSelector.scss";
import { Portal } from "react-portal";

const LanguageSelector = React.memo(
    ({
        globalTexts,
        nativeLanguages,
        currentSlug,
        setIsLanguageSelectorActive,
        isLanguageSelectorActive,
        setIsBackgroundColor,
        pageType,
    }) => {
        exists(window);
        const size = useWindowSize();
        const deviceBreakPoint = size.width < breakpoints.md;
        const isFrontpage = pageType === "frontpage";
        const isMapPage = pageType === "map";

        const stateClassName = classNames("language-selector", {
            "language-selector--active": isLanguageSelectorActive,
        });

        const openLanguageSelector = () => {
            setIsLanguageSelectorActive(true);
        };

        const closeLanguageSelector = () => {
            setIsLanguageSelectorActive(false);
        };

        const animateOverlay = {
            initial: {
                opacity: 0,
                borderRadius: "100%",
                width: "10rem",
                height: "10rem",
                scale: 0,
                bottom: "5rem",
                display: "flex",
                alignSelf: "center",
            },

            active: {
                opacity: 1,
                scale: 60, //deviceBreakPoint ? 30 : 60,
                left: 0,
                bottom: 0,
                top: 0,
                right: 0,
                transition: {
                    type: "tween",
                    ease: "easeIn",
                    duration: 0.7,
                    when: "beforeChildren",
                    staggerChildren: 0.05,
                },
                transitionEnd: {
                    borderRadius: "0",
                    width: "100%",
                    height: "100vh",
                    scale: 1,
                },
            },
        };

        const animateLanguageList = {
            initial: {
                opacity: 0,
                transition: {
                    when: "afterChildren",
                    staggerChildren: 0.05,
                },
            },

            enter: {
                opacity: 1,
                transition: {
                    when: "beforeChildren",
                    staggerChildren: 0.05,
                    delay: 0.5,
                },
            },
        };

        const animateLanguageItems = {
            initial: {
                y: 20,
                opacity: 0,
            },

            enter: {
                y: 0,
                opacity: 1,
                transition: {
                    type: "spring",
                    ease: "easeIn",
                    damping: 50,
                },
            },
        };

        const animateCloseIcon = {
            initial: {
                scale: 0.5,
                opacity: 0,
            },

            enter: {
                scale: 1,
                opacity: 1,
                transition: {
                    type: "tween",
                    ease: "easeIn",
                    delay: 0.5,
                },
            },
        };

        const alphaSort = (a, b) => {
            const nativeNameA = a.node.nativeName.toUpperCase();
            const nativeNameB = b.node.nativeName.toUpperCase();

            let comparison = 0;
            if (nativeNameA > nativeNameB) {
                comparison = 1;
            } else if (nativeNameA < nativeNameB) {
                comparison = -1;
            }
            return comparison;
        };
        const languagesAlphaSorted = nativeLanguages?.edges.sort(alphaSort);

        let handleHover;
        if (setIsBackgroundColor !== undefined) {
            handleHover = () => {
                setIsBackgroundColor("#242424");
            };
        }

        return (
            <>
                <motion.button
                    onClick={() => openLanguageSelector()}
                    className="toggle-language-selector"
                    whileTap={{ scale: 1.1 }}
                    onMouseOver={isFrontpage ? handleHover : () => null}
                >
                    {globalTexts.languageSelectorLabel}
                </motion.button>
                {isLanguageSelectorActive && (
                    <Portal>
                        <motion.div
                            key={
                                deviceBreakPoint
                                    ? "lang-mobile"
                                    : "lang-desktop"
                            }
                            className={stateClassName}
                            variants={animateOverlay}
                            initial="initial"
                            animate={
                                isLanguageSelectorActive ? "active" : "initial"
                            }
                        >
                            <motion.button
                                initial="initial"
                                animate="enter"
                                variants={animateCloseIcon}
                                onClick={() => closeLanguageSelector()}
                                className="close-btn"
                            >
                                <CloseSvg />
                            </motion.button>
                            <motion.ul
                                className="language-selector__list"
                                initial="initial"
                                animate="enter"
                                variants={animateLanguageList}
                            >
                                {languagesAlphaSorted.map((language, key) => {
                                    const currentSlugLocale =
                                        !isFrontpage && !isMapPage
                                            ? currentSlug?.edges.filter(
                                                  (item) => {
                                                      return (
                                                          item.node.locale ===
                                                          language.node.code
                                                      );
                                                  }
                                              )[0]
                                            : "";

                                    return (
                                        <motion.li
                                            className="language-selector__item"
                                            key={key}
                                            variants={animateLanguageItems}
                                            whileHover={{ x: 10 }}
                                        >
                                            {!deviceBreakPoint && (
                                                <ArrowRight />
                                            )}
                                            <a
                                                href={
                                                    !isFrontpage && !isMapPage
                                                        ? `${window.location.origin}/${language.node.code}/${currentSlugLocale.node.slug}`
                                                        : `${window.location.origin}/${language.node.code}`
                                                }
                                            >
                                                <GradientText
                                                    gradientText={
                                                        language.node.nativeName
                                                    }
                                                    heading={false}
                                                    fontSize={
                                                        deviceBreakPoint
                                                            ? "1.5rem"
                                                            : "2.4rem"
                                                    }
                                                    deviceFontSize={
                                                        deviceBreakPoint
                                                            ? "1.5rem"
                                                            : "2.4rem"
                                                    }
                                                />
                                            </a>
                                        </motion.li>
                                    );
                                })}
                            </motion.ul>
                        </motion.div>
                    </Portal>
                )}
            </>
        );
    }
);

export default LanguageSelector;
