// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-pages-article-js": () => import("./../../../src/components/pages/article.js" /* webpackChunkName: "component---src-components-pages-article-js" */),
  "component---src-components-pages-exhibition-video-js": () => import("./../../../src/components/pages/exhibition-video.js" /* webpackChunkName: "component---src-components-pages-exhibition-video-js" */),
  "component---src-components-pages-frontpage-js": () => import("./../../../src/components/pages/frontpage.js" /* webpackChunkName: "component---src-components-pages-frontpage-js" */),
  "component---src-components-pages-map-js": () => import("./../../../src/components/pages/map.js" /* webpackChunkName: "component---src-components-pages-map-js" */),
  "component---src-components-pages-message-js": () => import("./../../../src/components/pages/message.js" /* webpackChunkName: "component---src-components-pages-message-js" */),
  "component---src-components-pages-not-found-video-message-js": () => import("./../../../src/components/pages/not-found-video-message.js" /* webpackChunkName: "component---src-components-pages-not-found-video-message-js" */),
  "component---src-components-pages-research-js": () => import("./../../../src/components/pages/research.js" /* webpackChunkName: "component---src-components-pages-research-js" */),
  "component---src-components-pages-toolkits-js": () => import("./../../../src/components/pages/toolkits.js" /* webpackChunkName: "component---src-components-pages-toolkits-js" */),
  "component---src-pages-bg-404-js": () => import("./../../../src/pages/bg/404.js" /* webpackChunkName: "component---src-pages-bg-404-js" */),
  "component---src-pages-cs-404-js": () => import("./../../../src/pages/cs/404.js" /* webpackChunkName: "component---src-pages-cs-404-js" */),
  "component---src-pages-da-404-js": () => import("./../../../src/pages/da/404.js" /* webpackChunkName: "component---src-pages-da-404-js" */),
  "component---src-pages-de-404-js": () => import("./../../../src/pages/de/404.js" /* webpackChunkName: "component---src-pages-de-404-js" */),
  "component---src-pages-el-404-js": () => import("./../../../src/pages/el/404.js" /* webpackChunkName: "component---src-pages-el-404-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-es-404-js": () => import("./../../../src/pages/es/404.js" /* webpackChunkName: "component---src-pages-es-404-js" */),
  "component---src-pages-et-404-js": () => import("./../../../src/pages/et/404.js" /* webpackChunkName: "component---src-pages-et-404-js" */),
  "component---src-pages-fi-404-js": () => import("./../../../src/pages/fi/404.js" /* webpackChunkName: "component---src-pages-fi-404-js" */),
  "component---src-pages-fr-404-js": () => import("./../../../src/pages/fr/404.js" /* webpackChunkName: "component---src-pages-fr-404-js" */),
  "component---src-pages-ga-404-js": () => import("./../../../src/pages/ga/404.js" /* webpackChunkName: "component---src-pages-ga-404-js" */),
  "component---src-pages-hr-404-js": () => import("./../../../src/pages/hr/404.js" /* webpackChunkName: "component---src-pages-hr-404-js" */),
  "component---src-pages-hu-404-js": () => import("./../../../src/pages/hu/404.js" /* webpackChunkName: "component---src-pages-hu-404-js" */),
  "component---src-pages-it-404-js": () => import("./../../../src/pages/it/404.js" /* webpackChunkName: "component---src-pages-it-404-js" */),
  "component---src-pages-lt-404-js": () => import("./../../../src/pages/lt/404.js" /* webpackChunkName: "component---src-pages-lt-404-js" */),
  "component---src-pages-lv-404-js": () => import("./../../../src/pages/lv/404.js" /* webpackChunkName: "component---src-pages-lv-404-js" */),
  "component---src-pages-mt-404-js": () => import("./../../../src/pages/mt/404.js" /* webpackChunkName: "component---src-pages-mt-404-js" */),
  "component---src-pages-nl-404-js": () => import("./../../../src/pages/nl/404.js" /* webpackChunkName: "component---src-pages-nl-404-js" */),
  "component---src-pages-pl-404-js": () => import("./../../../src/pages/pl/404.js" /* webpackChunkName: "component---src-pages-pl-404-js" */),
  "component---src-pages-pt-404-js": () => import("./../../../src/pages/pt/404.js" /* webpackChunkName: "component---src-pages-pt-404-js" */),
  "component---src-pages-ro-404-js": () => import("./../../../src/pages/ro/404.js" /* webpackChunkName: "component---src-pages-ro-404-js" */),
  "component---src-pages-sk-404-js": () => import("./../../../src/pages/sk/404.js" /* webpackChunkName: "component---src-pages-sk-404-js" */),
  "component---src-pages-sl-404-js": () => import("./../../../src/pages/sl/404.js" /* webpackChunkName: "component---src-pages-sl-404-js" */),
  "component---src-pages-sv-404-js": () => import("./../../../src/pages/sv/404.js" /* webpackChunkName: "component---src-pages-sv-404-js" */),
  "component---src-pages-tr-404-js": () => import("./../../../src/pages/tr/404.js" /* webpackChunkName: "component---src-pages-tr-404-js" */)
}

